*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}


  




  .header {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 20px;
    justify-content: space-between ;
    display:flex;
    align-items: center;
    z-index: 100;
    color: white;
    background:transparent;
    
  }
 .logo {
    font-size: 50px;
    text-decoration:none;
    font-weight: 600;
    color: white;
}
  
  .navbar a {
    color: white;
    text-decoration: none;
    font-size: 25px;
    font-weight:500;
    margin-left:35px;
    transition:3s;
  }

  .navbar a:hover,
  .navbar a.active {
    color: #00abf0;
  }
  