body{
background-color: #182f61;

}

/* padding to space out page  */



/* intro */
h1.intro1 {
  text-align: center;
  padding-top: 250px;
  color:white;
}
h1.intro2 {
  text-align: center;
  color:white;
} 
/* intro */


/* waving hand emoji */
.emoji {
  font-size: 75px;
  display: inline-block; /* Ensure inline-block display for animation */
  animation: hand 2.5s infinite;
  transform-origin: 70% 70%;
}

@keyframes hand {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4.0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* waving hand emoji */





/* services  */
.service-text{
  text-align: center;
  color:white
}
.services{
  padding-top: 500px;
}

.service-container{
  display: flex;
  margin: 100px;
  gap: 415px;
  /*  Color of text*/
  color:white;
  
}
/* services */



/* about */



.know-container{
  text-align: center;
  padding-top:150px;
  color:white;
}
.about-container {
  display: flex;
  margin: 40px;
  text-align: center;
  color:white;
  font-size: 20px;
 }

.languages-container box{
  flex: 1;
  padding: 20px;
  }

 
/* about  */



/* Video-bar  */

.video-wrapper {
  position: relative;
  width: 100%;
  height: 600px;
}

.background-video {
  width: 100%;
  height: 1000px;
  object-fit: cover;
 padding-top:100px;
}
 
/* Video-bar  */

 
/* service images and text*/
.webdevtext{
  text-align: center;
}
.webddestext{
  text-align: center;
}
.appdevtext{
  text-align: center;
}
.web-des{
  margin-right: 30%;
}
/* service images and text */

/* Languages */

.skill-list {
  display: flex;
  margin: 1.5rem auto;
  justify-content: space-around;
  flex-wrap: wrap;
}
.skills-tab{
  color:white;
  font-size: 50px;
  padding-top:100px;
}
.logos {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.skill-logo {
    /* background-color: var(--trans-grey); */
    border: .25px solid var(--trans-grey);
    box-shadow: 5px 5px 20px var(--trans-grey);
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
width: 5rem;
height: 5rem;
}
.logo-img {
  width: 100px;
}
.logos p {
    line-height: 3;
    font-weight: 600;
    font-size: ;
    color:white;
}
@media screen and (min-width: 768px) {
  .skill-list {
    padding: 0 3rem;
  }
}
@media screen and (min-width: 1024px) {
#skills-section .sections-heading {
  text-align: center;
}
#skills-section {
  padding: 1rem 10rem;
}
}
@media screen and (min-width: 1660px) {
  #skills-section {
    padding: 1rem 17rem;
  }
}


/* Languages */